<template>
  <div class="years" ref="years">
    <div class="years__item" v-for="(item, key) in years" :key="item">
      <div class="years__title">
        <h3 v-if="key % 2 == 0 || size.xs" class="year-title">
          {{ item }}
        </h3>
        <div v-else class="year-description">
          <div v-if="item == 2015" class="year-2015">
            <Icon name="alexa" />
          </div>
          <div v-if="item == 2019"  class="year-2019">
            <img src="/join/1.jpeg" alt="">
            <img class="year-2019__two" src="/join/2.jpeg" alt="">
            <img class="year-2019__three" src="/join/3.jpeg" alt="">
            <img class="year-2019__four" src="/join/4.jpeg" alt="">
            <div class="year-2019__plus">+</div>
          </div>
          {{ $t('years.' + item) }}
        </div>
      </div>
      <div class="years__description">
        <div class="years__description--line"></div>
        <div class="years__description--circle"></div>
        <div v-if="key % 2 == 0 || size.xs" class="year-description">
          <div v-if="item == 2014" class="year-2014">
            <div class="year-2014__flag">
              <Icon name="en" />
            </div>
            <div class="year-2014__plus">+</div>
          </div>
          <div v-if="item == 2015" class="year-2015">
            <Icon name="alexa" />
          </div>
          <div v-if="item == 2019"  class="year-2019">
            <img src="/join/1.jpeg" alt="">
            <img class="year-2019__two" src="/join/2.jpeg" alt="">
            <img class="year-2019__three" src="/join/3.jpeg" alt="">
            <img class="year-2019__four" src="/join/4.jpeg" alt="">
            <div class="year-2019__plus">+</div>
          </div>
          <div v-if="item == 2024" class="year-2024">
            <a href="https://www.instagram.com/loftytoday?igsh=MWk1ejMzbG01YmlpaA==" target="_blank">
              <Icon name="instagram" />
            </a>
            <a href="https://hh.ru/employer/4137277" target="_blank">
              <Icon name="hh" />
            </a>
            <a href="https://ru.linkedin.com/company/lofty-today" target="_blank">
              <Icon name="linkedin" />
            </a>
            <a href="https://career.habr.com/companies/loftytoday" target="_blank">
              <Icon name="habr" />
            </a>
          </div>
          {{ $t('years.' + item) }}
        </div>
        <h3 v-else class="year-title">
          {{ item }}
        </h3>
      </div>
    </div>
    <div class="years__button-wrapper">
      <button @click="showCards()" class="years__button" v-if="size.xs">
        {{ $t(button) }}
      </button>
    </div>
  </div>
</template>
<script>
import { useSize } from "@/js/useSize";

  export default {
    setup() {
      const { size } = useSize();

      return {
        size,
      }
    },
    data() {
      return {
        top: 0,
        currentLength: 2016,
        button: 'years.button_more',
      }
    },
    methods: {
      showCards() {
        if (this.currentLength == 2025) {
          this.currentLength = 2016;
          this.button = 'years.button_more';
        } else {
          this.currentLength = 2025;
          this.button = 'years.button_less';
        }
      },
      handleScroll() {
        this.$refs.years.querySelectorAll('.years__item').forEach(item => {
          const line = item.querySelector('.years__description--line');
          const circle = item.querySelector('.years__description--circle');
          let size = 0;

          if (item.getBoundingClientRect().top < 230) {
            if (item.getBoundingClientRect().top < 186) {
              circle.classList.add('active')
            } else {
              circle.classList.remove('active')
            }

            size = 230 - item.getBoundingClientRect().top;

            if (size > 100) {
              size = 100
            }

            line.style.height = size +'%';
          } else {
            
            circle.classList.remove('active')
            size = 0;
            line.style.height = size +'%';
          }
        })
      }
    },
    computed: {
      years () {
        const array = [];
        if (this.size.xs && this.currentLength == 2016) {
          for (let i = 2011; i < 2016; i++) {
            if (i !== 2013) {
              array.push(i)
            }
          }
        } else {
          for (let i = 2011; i < 2025; i++) {
            if (i !== 2013) {
              array.push(i)
            }
          }
        }
        return array
      }
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll);
    },
    unmounted() {
      window.removeEventListener("scroll", this.handleScroll);
    }
  }
</script>
<style lang="scss">
  .years {
    width: 100%;
    margin-bottom: 64px;
    max-width: 750px;

    @media (max-width: 768px) {
      margin-bottom: 40px;
    }

    &__item {
      display: flex;
    }

    &__title {
      flex: 1;
      padding: 40px 36px;
      align-items: center;
      display: flex;
      justify-content: flex-end;

      @media (max-width: 768px) {
        padding: 40px 36px 40px 0;
      }
    }

    &__description {
      flex: 1;
      padding: 40px 36px;
      position: relative;
      display: flex;
      align-items: center;

      @media (max-width: 768px) {
        padding: 40px 0 40px 36px;
      }

      @media (max-width: 450px) {
        flex: 2;
      }

      &:after {
        content: '';
        position: absolute;
        height: 100%;
        left: 0;
        top: 0;
        width: 5px;
        background: $gray;
      }

      &--line {
        content: '';
        position: absolute;
        height: 0;
        left: 0;
        top: 0;
        width: 5px;
        background: $accent-dark;
        z-index: 1;
      }

      &--circle {
        content: '';
        position: absolute;
        width: 32px;
        height: 32px;
        left: 0;
        top: 50%;
        background: $gray;
        z-index: 1;
        border-radius: 50%;
        transform: translate(-14px, -50%);

        &:after {
          content: '';
          position: absolute;
          width: 14px;
          height: 14px;
          left: 50%;
          top: 50%;
          background: $white;
          z-index: 1;
          border-radius: 50%;
          transform: translate(-50%, -50%);
        }

        &:before {
          content: '';
          position: absolute;
          width: 32px;
          height: 32px;
          left: 50%;
          top: 50%;
          background: $accent-dark;
          z-index: 1;
          border-radius: 50%;
          transform: translate(-50%, -50%);
          display: none;
        }

        &.active {
          &:before {
            display: flex;
          }
        }
      }
    }

    &__button {
      font-family: Geometria, sans-serif;
      font-size: 16px;
      font-weight: 700;
      line-height: 23px;
      text-align: center;
      padding: 16px 24px;
      color: $text-main;
      background: $accent-dark;
      border-radius: 12px;
      border: none;

      &-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 24px;
      }
    }
  }

.year-title {
  font-family: Geometria, sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.03em;
  text-align: center;

  @media (max-width: 450px) {
    font-size: 24px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: -0.03em;
  }
}

.year-description {
  font-family: Geometria, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.03em;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  position: relative;

  @media (max-width: 450px) {
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: -0.03em;
  }
}

.year-2015 {
        position: absolute;
        top: -42px;
        padding: 6px 8px;
        border-radius: 30px;
        background: #D3DEF3;

  .svg-icon {
          width: 46px;
          height: 14px;
  }
}

.year-2019 {
        position: absolute;
        top: -36px;
        display: flex;
        flex-direction: row;
        align-items: center;

  img {
          border-radius: 50%;
          width: 26px;
          height: 26px;
  }

  &__two {
            transform: translateX(-20%);
  }

  &__three {
            transform: translateX(-40%);
  }

  &__four {
            transform: translateX(-60%);
  }

  &__plus {
          backdrop-filter: blur(10px);
          transform: translateX(-20px);
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          width: 26px;
          height: 26px;
          font-weight: 700;
          background: rgba(141, 234, 228, 0.35);
          border: 1px solid rgba(141, 234, 228, 0.1);
          font-size: 15px;
  }
}

.year-2014 {
        display: flex;
        flex-direction: row;
        position: absolute;
        top: -36px;

  &__flag {
          width: 26px;
          height: 26px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #B6D5FF;
          border: 1px solid #B6D5FF;
          border-radius: 50%;

    .svg-icon {
            width: 12px;
            height: 12px;
    }
  }

  &__plus {
          backdrop-filter: blur(10px);
          transform: translateX(-6px);
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          width: 26px;
          height: 26px;
          font-weight: 700;
          background: rgba(141, 234, 228, 0.35);
          border: 1px solid rgba(141, 234, 228, 0.1);
          font-size: 15px;
  }
}

.year-2024 {
        position: absolute;
        top: -42px;
        display: flex;
        flex-direction: row;
        gap: 5px;

        a {
          transition: 0.3s;
          border: 2px solid transparent;
          border-radius: 50%;
          width: 32px;
          height: 32px;
          overflow: hidden;

          .svg-icon {
            border-radius: 50%;
            transition: 0.3s;  
          }

          &:hover {
            border: 2px solid $accent-dark;

            .svg-icon {
              transform: scale(1.1);
            }
          }
        }
}
</style>