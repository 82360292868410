<template>
  <section class="employee container">
    <div class="employee__navigation">
      <h3>
        {{$t('employee.title')}}
      </h3>
      <div class="swiper__navigation">
        <div class="swiper__prev" @click="swiper.slidePrev()">
          <Icon name="arrow"></Icon>
        </div>
        <div class="swiper__next" @click="swiper.slideNext()">
          <Icon name="arrow"></Icon>
        </div>
      </div>
    </div>
    <div class="employee__items">
      <swiper
        :slides-per-view="sliderCount"
        @init="onInit"
        :space-between="14"
        :loop="true">
        <swiper-slide
          v-for="item in items"
          :key="item"
          @click="toogleClass($event, 1)"
          @mouseenter="toogleClass($event, 2)"
          @mouseleave="toogleClass($event, 2)"
        >
            <div class="flip-card">
              <div class="flip-card-inner">
                <div v-lazy="'/employee/' + item + '.jpg'" class="flip-card-front">
                  <div class="employee__title">
                    <h3>{{$t('employee.items.'+item+'.name')}}</h3>
                    <p>{{$t('employee.items.'+item+'.position')}}</p>
                  </div>
                </div>
                <div class="flip-card-back">
                  <div v-html="$t('employee.items.'+item+'.post')" class="employee__description"></div>
                  <div class="employee__title">
                    <div>
                      <h3>{{$t('employee.items.'+item+'.name')}}</h3>
                      <p>{{$t('employee.items.'+item+'.position')}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </swiper-slide>
      </swiper>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';

import SwiperCore, { Pagination } from 'swiper';
import { useSize } from "@/js/useSize";
import { computed } from "vue";
SwiperCore.use([Pagination]);

  export default {
    setup() {
      const { size } = useSize();

      const toogleClass = (event, stat) => {
        if (stat == 1 && size.s) {
          event.currentTarget.classList.toggle("onclickToogle");
        }

        if (stat == 2 && !size.s) {
          event.currentTarget.classList.toggle("onclickToogle");
        }
      };

      const sliderCount = computed(() => {
        if (size.s) {
          return 1;
        } else if (size.md) {
          return 2;
        } else {
          return 3;
        }
      });
      return {
        size,
        sliderCount,
        toogleClass
      }
    },
    
    data() {
      return {
        items: ['karina', 'lena', 'ilya', 'dima', 'lina', 'dasha', 'serg']
      }
    },
    methods: {
      onInit(swiper) {
        this.swiper = swiper
      }
    },
    components: {
      Swiper,
      SwiperSlide
    }
  }
</script>

<style lang="scss">
  .employee {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-bottom: 64px;

    @media (max-width: 1440px) {
      gap: 24px;
    }

    @media (max-width: 768px) {
      gap: 16px;
      margin-bottom: 40px;
    }

    @media (max-width: 360px) {
      margin-bottom: 0;
      background: $accent;
    }

    &__navigation {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 40px;
      align-items: center;

      h3 {
        color: $text-main;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        letter-spacing: -1.2px;

        @media (max-width: 768px) {
          font-size: 32px;
        }

        @media (max-width: 360px) {
          font-size: 24px;
        }
      }
    }

    &__items {
      display: flex;
    }

    &__item {
      display: flex;
      width: 100%;

      img {
        width: 100%;
      }
    }

    &__title {
      position: absolute;
      bottom: 0;
      padding: 24px;
      width: 100%;
      text-align: left;
      left: 0;

      @media (max-width: 768px) {
        padding: 20px;
      }

      h3 {
        color: $white;
        margin-bottom: 8px;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        letter-spacing: -1.2px;
      }

      p {
        color: $accent-dark;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.6px;
      }
    }

    &__description {
      text-align: left;
      font-family: Geometria;
      flex: 1;
      font-size: 20px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.03em;

      @media (max-width: 1440px) {
        font-size: 16px;
        line-height: 16px;
      }

      @media (max-width: 1130px) {
        font-size: 14px;
        line-height: 14px;
      }

      @media (max-width: 768px) {
        font-size: 14px;
        line-height: 14px;
      }

      @media (max-width: 660px) {
        font-size: 11px;
        line-height: 11px;
      }

      @media (max-width: 550px) {
        font-size: 14px;
        line-height: 14px;
      }
    }

    &__avatar {
      min-width: 50px;
      max-width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 10px;
      background-size: cover;
    }
  }

  .flip-card {
    background-color: transparent;
    width: 504px;
    height: 640px;
    //aspect-ratio: 13 / 16 !important;
    perspective: 1000px;

    @media (max-width: 1440px) {
      width: 405px;
      height: 540px;
    }

    @media (max-width: 1024px) {
      width: 550px;
      height: 600px;
    }

    @media (max-width: 768px) {
      width: 360px;
      height: 450px;
    }

    @media (max-width: 550px) {
      width: 550px;
      height: 600px;
    }

    @media (max-width: 360px) {
      width: 360px;
      height: 450px;
    }

    // &:hover {
    //   .flip-card-inner {
    //       transform: rotateY(180deg);
    //   }
    // }
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }



  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background-size: cover;
    border-radius: 15px;
    background-color: $gray;
  }

  .flip-card-back {
    padding: 24px;
    display: flex;
    flex-direction: column;
    transform: rotateY(180deg);
    background-color: $dark;
    color: $white;

    @media (max-width: 768px) {
      padding: 20px;
    }

    .employee__title {
      display: flex;
      align-items: center;
      padding: 0;
      width: 100%;
      position: relative;

      h3 {
        color: $white;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        letter-spacing: -1.2px;
      }
      p {
        color: $accent-dark;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.6px;
      }
    }
  }

  .no-touch {
    .flip-card {
      &-back {
        transform: rotateY(180deg);
        background-color: $dark;
        color: $white;

        .employee__title {
          h3 {
            color: $white;
          }
          p {
            color: $accent-dark;
          }
        }
      }

      // &:hover {
      //   .flip-card-inner {
      //       transform: rotateY(180deg);
      //   }
      // }
    }

    // .flip-card-back {
    //   &:hover {
    //     .flip-card-inner {
    //         transform: rotateY(0deg) !important;
    //     }
    //   }
    // }
  }

.swiper__next,
.swiper__prev {
  border-radius: 34px;
  border: 1px solid #EAEBEC;
  background: #F4F5F5;
  width: 40px;
  height: 40px;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;

  .svg-icon {
    width: 6px;
    height: 12px;
  }

  &:hover {
    border: 1px solid #D5D7D9 !important;
    background: #E9EAEB !important;
  }
}

.onclickToogle {
  .flip-card-inner {
    transform: rotateY(180deg);
  }
}
</style>