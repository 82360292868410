<template>
    <div class="nav">
      <router-link class="nav__link" :to="{ name: 'home', hash: '#office'}">
        <Icon name="header-note" />
        {{ $t("header.links.about") }}
      </router-link>
      <router-link class="nav__link" :to="{ name: 'home', hash: '#products'}">
        <Icon name="header-bag" />
        {{ $t("header.links.products") }}
      </router-link>
      <router-link class="nav__link" :to="{ name: 'home', hash: '#stack'}">
        <Icon name="header-tech" />
        {{ $t("header.links.technologies") }}
      </router-link>
      <router-link class="nav__link" :to="{ name: 'home', hash: '#loftyIs'}">
        <Icon name="header-rocket" />
        {{ $t("header.links.history") }}
      </router-link>
      <router-link class="nav__link nav__link__accent" :to="{ name: 'jobs'}">
        <Icon name="header-bag" />
        {{ $t("header.links.vacancies") }}
      </router-link>
    </div>
</template>

<style scoped lang="scss">
.nav {
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: center;

  @media (max-width: 1440px) {
    gap: 20px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 8px;
  }

  &__link {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 8px 30px 8px 8px;
    border-radius: 8px;
    border: 1px solid $border-color;
    transition: 0.3s;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    white-space: nowrap;

    &:hover {
      background: rgba(46, 57, 72, 0.05);
    }

    &__accent {
      background: #8DEAE4CC;
    }

    @media (max-width: 1440px) {
      padding: 8px 16px 8px 8px;
    }

    @media (max-width: 1160px) {
      padding: 5px;
      border-radius: 5px;
      .svg-icon {
        display: none;
      }
    }

    @media (max-width: 768px) {
      width: 100%;
      box-sizing: border-box;
      border: none;
      border-top: 1px solid $border-color;
      border-bottom: 1px solid $border-color;
      border-radius: 0;

      .svg-icon {
        display: block;
        background: rgba(46, 57, 72, 0.05);
        border-radius: 8px;
      }
    }
  }
}
</style>