<template>
  <section class="stack__wrapper" id="stack">
    <div class="stack container">
      <h3 class="stack__title" v-html="$t('stack.title')"></h3>
      <h3 class="stack__title-two">{{ $t('stack.title_two') }}</h3>
      <div class="stack__nav">
        <div
          v-for="(item, key) in DEPARTMENTS"
          :key="key"
          class="stack__nav__link"
          @click="changheCard(item)"
        >
          <span>{{ item.title }}</span>
          <div class="btn-transition"></div>
        </div>
      </div>
      <div class="stack__main-block">
        <StackCard :currentCard="currentCard" />
        <StackCircle :currentCard="currentCard" />
      </div>
    </div>
  </section>
</template>

<script setup>
import StackCard from "@/components/Home/Stack/StackCard.vue";
import StackCircle from "@/components/Home/Stack/StackCircle.vue";
import { DEPARTMENTS } from "@/constants.js"
import { ref } from "vue";

const currentCard = ref(DEPARTMENTS.marketing);

const changheCard = (item) => {
  currentCard.value = item; 
};
</script>

<style scoped lang="scss">
.stack {
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (max-width: 768px) {
    gap: 16px;
  }

  &__title {
    @media (max-width: 768px) {
      display: none;
    }

    &-two {
      display: none;

      @media (max-width: 768px) {
        display: block
      }
    }
  }

  h3 {
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -1.2px;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 28px;
      line-height: 96%;
      letter-spacing: -0.84px;
    }
  }

  &__wrapper {
    background: $accent-two;
    width: 100%;
    padding: 64px 0;

    @media (max-width: 768px) {
      padding: 40px 0;
    }
  }

  &__nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px;

    &__link {
      cursor: pointer;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 14px;
      padding: 20px 12px;
      border-radius: 100px;
      border: 1px solid $border-color;
      width: 150px;
      box-sizing: border-box;
      text-align: center;
      position: relative;
      overflow: hidden;
      transition: 0.3s;

      .btn-transition {
        background: #9FEDE8;
        width: 0;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transition: 0.6s;
      }

      span {
        position: relative;
        z-index: 1;
      }

      &:hover {
        background: $white;
        .btn-transition {
          width: 100%;
        }
      }

      @media (max-width: 1124px) {
        width: max-content;
        padding: 12px;
      }

      @media (max-width: 768px) {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 10px;
        padding: 15px 13px;
      }
    }

    @media (max-width: 768px) {
      gap: 8px;
    }
  }

  &__main-block {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    @media (max-width: 1440px) {
      justify-content: space-between;
      gap: 30px;
    }

    @media (max-width: 1124px) {
      flex-direction: column;
    }
  }
}
</style>

<!-- <style lang="scss" scoped>
  .stack {
    $container-size-circle: 360px;
    $element-size: 60px;
    $container-size-ring: 560px;

    @media (max-width: $tablet-point) {
      $element-size: 56px;
      $container-size-circle: 260px;
      $container-size-ring: 460px;
    }


    &__left, &__right {
      flex: 1;
    }


    h3 {
      margin-bottom: 25px;
    }

    p {
      margin-bottom: 50px;
    }

    li {
      margin-bottom: 15px;
      font-weight: 600;
      font-size: 18px;
    }

    &__count {
      margin-right: 10px;
      color: $gray-text;
      font-weight: 400;
      font-size: 14px;
    }


    &__right {
      display: flex;
      justify-content: center;
      align-items: center;


      @media (max-width: $tablet-point) {
        display: none;
      }
    }



    &__ring {
      border-radius: 50%;
      border: 3px solid $accent;
      padding: 100px;
      position: relative;
      min-width:   $container-size-ring;
      max-width:   $container-size-ring;
      height:   $container-size-ring;
      transform: rotate(180deg);

      .stack__icon {
        transform-origin: 50% calc(#{$element-size} / 2 -  #{$container-size-ring} / 2)
      }
    }

    &__circle {
      min-width:  $container-size-circle;
      max-width:  $container-size-circle;
      height:  $container-size-circle;
      border-radius: 50%;
      position: absolute;
      border: 50px solid $accent;
      transform: rotate(180deg);

      @media (max-width: $tablet-point) {
        border: 40px solid $accent;
      }


      .stack__icon {
        transform-origin: 50% calc(#{$element-size} / 2 -  (#{$container-size-circle} - 100px) / 2);
      }
    }

    &__lofty {
        background: $accent-dark;
        width: 72px;
        height: 72px;
        border-radius: 50%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: absolute;

        .svg-icon {
          width: 46px;
        }
    }

    &__icon {
      min-width: $element-size;
      max-width: $element-size;
      background: $white;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: $element-size;
      height: $element-size;
      border-radius: $element-size;
      left: 50%;
      top: 100%;

      .svg-icon {

      }

      &:hover {
        &:after {
          display: flex;
        }
      }
    }

    &__box {
      display: flex;

      &:after {
        display: none;
        content: attr(data-title);
        background: $gray;
        border-radius: 5px;
        color: $dark;
        padding: 5px 15px;
        position: absolute;
        text-transform: capitalize;
        align-items: center;
        left: 50%;
        bottom: 100%;
        transform: translate(-50%, -20px);
      }
    }

    @for $i from 1 through 9 {
      @for $j from 1 through $i {
        .stack__icon:nth-child(#{$j}):nth-last-child(#{$i - $j}) {
          transform: translate(-50%, -50%) rotate(360deg * calc(($j - 1) / ($i - 1)));

          .stack__box{
            transform: rotate(-360deg * calc(($j - 1) / ($i - 1)) + 180deg);
          }
        }
      }
    }
  }

  .no-touch {
    .stack li a {
      &:hover {
        color: $gray-text;
      }
    }

    .stack__icon {
      &:hover {
        .stack__box {
          &:after {
            display: flex;
          }
        }
      }
    }
  }
</style> -->