<template>
  <div class="scroll__up"  :class="{'scroll__up--bottom': props.bottom}" @click="scrollTop">
    <div class="scroll__up--box">
      <Icon name="arrow2"></Icon>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps(['bottom']);

const scrollTop = () => {
  window.scroll({ top: 0, left: 0, behavior: 'smooth' });
}
</script>

<style lang="scss" scoped>
  .scroll__up {
    position: fixed;
    bottom: 2%;
    right: 2%;
    z-index: 1000;
    transition: none;
    background: transparent;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    user-select: none;

    .svg-icon {
      transform: rotate(90deg);
      color: $accent;

      height: 24px;
      max-width: 24px;
      min-width: 24px;
    }

    &--bottom {
      position: absolute;
      bottom: 105%;
    }

    &--box {
      background: $gray;

      height: 56px;
      max-width: 56px;
      min-width: 56px;
      border-radius: 50%;

      display: flex;
      align-items: center;
      justify-content: center;
      -webkit-tap-highlight-color: transparent;
      user-select: none;
    }
  }

  .no-touch {
    .scroll__up {
      &:hover {
        background: rgba(228, 232, 240, 0.3);
      }
    }
  }
</style>