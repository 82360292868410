<template>
    <div class="footer-card">
        <div v-if="!isHomePage" class="footer-card__form-info">
            <div class="footer-card__form-info__upper">
                <h3>{{ $t('footer.footer_card.card_info.title') }}</h3>
                <span v-html="$t('footer.footer_card.card_info.subtitle')"></span>
            </div>
            <div class="footer-card__form-info__lower">
                <span class="footer-card__contacts-card__info">
                    <Icon name="map-tag" />
                    <span>{{ $t("footer.footer_card.card_contacts.adress") }}</span>
                </span>
                <a
                    href="mailto:info@lofty.today"
                    class="footer-card__contacts-card__info footer-card__link"
                >
                    <Icon name="mail" />
                    <span>info@lofty.today</span>
                </a>
                <a
                    href="https://dreamjob.ru/employers/144101?utm_source=hh"
                    target="_blank"
                    class="footer-card__contacts-card__info footer-card__link"
                >
                    <Icon name="hh-rev" />
                    <span>{{ $t("footer.footer_card.card_contacts.reviews") }}</span>
                </a>
            </div>
            <img class="footer-card__form-info__img" src="/photos/hr1.png" alt="">
        </div>
        <div class="footer-card__form-card">
            <span v-if="isHomePage" class="footer-card__title">
                {{ $t("footer.footer_card.card_form.title") }}
            </span>
            <input
                class="input"
                type="text"
                v-model="candidate.name"
                :placeholder="$t('footer.footer_card.card_form.name')"
            >
            <div class="input-error" v-if="errors && errors.name">{{$t('form.error.name')}}</div>
            <input
                class="input"
                type="mail"
                v-model="candidate.email"
                :placeholder="$t('footer.footer_card.card_form.email')"
            >
            <div class="input-error" v-if="errors && errors.email">{{$t('form.error.email')}}</div>
            <textarea
                class="textarea"
                id=""
                cols="30"
                rows="10"
                v-model="candidate.text"
                :placeholder="$t('footer.footer_card.card_form.comment')"
            ></textarea>
            <div class="input-error" v-if="errors && errors.text">{{ $t('form.error.text') }}</div>
            <button class="btn footer-card__button" @click="sendForm()">
                {{ $t('footer.footer_card.card_form.send') }}
            </button>
        </div>
        <div v-if="isHomePage" class="footer-card__contacts-card">
            <span class="footer-card__title">{{ $t("footer.footer_card.card_contacts.title") }}</span>
            <span class="footer-card__contacts-card__info">
                <Icon name="map-tag" />
                <span>{{ $t("footer.footer_card.card_contacts.adress") }}</span>
            </span>
            <a
                href="mailto:info@lofty.today"
                class="footer-card__contacts-card__info footer-card__link"
            >
                <Icon name="mail" />
                <span>info@lofty.today</span>
            </a>
            <a
                href="https://dreamjob.ru/employers/144101?utm_source=hh"
                target="_blank"
                class="footer-card__contacts-card__info footer-card__link"
            >
                <Icon name="hh-rev" />
                <span>{{ $t("footer.footer_card.card_contacts.reviews") }}</span>
            </a>
            <img class="footer-card__cash" src="/footer_img.svg" alt="cash" />
        </div>
    </div>
</template>

<script setup>
import { computed, reactive, ref } from "vue";
import { useRoute } from "vue-router";
import { sendContact } from "@/api";
import { grecaptchaExecute } from "@/grecaptcha";
import { notify } from "@kyvg/vue3-notification";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const route = useRoute();

const candidate = reactive({
    name: '',
    email: '',
    text: ''
})

const errors = ref();

const isLoading = ref(false);

const clearErrors = () => {
    errors.value = {};
};

const sendForm = async () => {
    clearErrors();
  
    if (!candidate.name) {
        errors.value.name = true;
    }
  
    if (!candidate.text || candidate.text.length < 10) {
        errors.value.text = true;
    }
  
    const mailformat = /\S+@\S+\.\S+/;
  
    if (!candidate.email || !candidate.email.match(mailformat)) {
        errors.value.email = true;
    }
  
    if (isLoading.value || errors.value.name || errors.value.email || errors.value.text) {
        return
    }
  
    isLoading.value = true;

    // const captcha =
    //     await getCurrentInstance().appContext.config.globalProperties.$grecaptchaExecute;

    let captcha = await grecaptchaExecute();
  
    try {
        await sendContact({...candidate, captcha});

        notify({
            group: 'foo',
            title: t('form.stat_success'),
        });
  
        clearErrors();
        candidate.email = '';
        candidate.name = '';
        candidate.text = '';
  
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        notify({
            group: 'foo',
            title: t('form.stat_error'),
        });

    } finally {
        isLoading.value = false;
    }
};

const isHomePage = computed(() => {
    if (route.name == 'home') {
        return true;
    } else {
        return false;
    }
});
</script>

<style scoped lang="scss">
.footer-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 24px;
    width: 100%;

    &__link {
        &:hover {
            background: $accent;
            .svg-icon {
                opacity: 1;
            }
        }
    }

    &__title {
        color: $text-main;
        font-size: 40px;
        font-weight: 700;
        line-height: 100%;
        margin-bottom: 16px;

        @media (max-width: 768px) {
          font-size: 32px;
        }

        @media (max-width: 360px) {
          font-size: 24px;
        }
    }

    &__form-info {
        background: $white;
        border-radius: 16px;
        padding: 24px;
        width: 100%;
        max-width: 768px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 94px;

        @media (max-width: 1440px) {
            width: 60%;
        }

        @media (max-width: 768px) {
            width: 100%;
        }

        &__upper {
            display: flex;
            flex-direction: column;
            gap: 16px;

            h3 {
                font-size: 40px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                letter-spacing: -2px;

                @media (max-width: 360px) {
                    font-size: 24px;
                }
            }

            span {
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%;
                letter-spacing: -0.72px;

                @media (max-width: 360px) {
                    font-size: 16px;
                }
            }
        }

        &__lower {
            display: flex;
            flex-direction: column;
            gap: 16px;
            align-items: flex-start;
            z-index: 1;
        }

        &__img {
            position: absolute;
            bottom: 20px;
            right: 20px;
            @media (max-width: 768px) {
                display: none;
            }
        }
    }

    &__form-card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        width: 40%;
        max-width: 40%;

        @media (max-width: 1440px) {
            max-width: 50%;
            width: 50%;
        }

        .textarea {
            height: 100%;
        }
    }

    &__button {
        margin-top: 8px;
    }

    &__contacts-card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        max-width: 50%;

        &__info {
            display: flex;
            flex-direction: row;
            gap: 16px;
            align-items: center;
            padding: 5px;
            border-radius: 5px;
            transition: 0.3s;

            .svg-icon {
                width: 24px;
                height: 24px;
                opacity: 0.5;
                transition: 0.3s;
            }

            span {
                font-size: 20px;
                font-weight: 500;
                line-height: 100%;
            }
        }
    }

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 36px;

        &__form-card {
            width: 100%;
            max-width: 100%;
        }

        &__contacts-card {
            width: 100%;
            max-width: 100%;
        }
    }

    &__cash {
        margin-top: 44px;

        @media (max-width: 400px) {
            width: 100%;
        }
    }
}
</style>