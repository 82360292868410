export const DEPARTMENTS = {
  'marketing': {
    dir: 'marketing',
    title: 'Marketing',
    text: 'stack.cards.marketing.main_text',
    tags: [
      'CEO',
      'Affiliate',
      'Target',
      'Context',
      'SMM',
      'Content',
      'Influence',
      'Community'
    ],
    firstCircle: ['miro', 'yametrika', 'jira'],
    secondCircle: ['youtube', 'googleanalytics', 'vk', 'tg', 'atlassian', 'figma', 'x']
  },
  'development': {
    dir: 'development',
    title: 'Development',
    text: 'stack.cards.development.main_text',
    tags: [
      'QA',
      'DevOps',
      'UX/UI',
      'Developer'
    ],
    firstCircle: ['laravel', 'postgre', 'python'],
    secondCircle: ['golang', 'php', 'nuxt', 'js', 'vue', 'clickhouse', 'linux']
  },
  'commerce': {
    dir: 'commerce',
    title: 'Commerce',
    text: 'stack.cards.commerce.main_text',
    tags: [
      'Unit Support',
      'Accounting',
      'Sales'
    ],
    secondCircle: ['google', 'jira', 'grafana', 'skype', 'confluence', 'tg', 'linkin']
  },
  'data': {
    dir: 'data',
    title: 'Analysis',
    text: 'stack.cards.data.main_text',
    tags: [
      'Data',
      'Product Analyst',
      'Integrator',
      'System',
      'Business'
    ],
    firstCircle: ['yametrika', 'googleanalytics', 'jira'],
    secondCircle: ['brandanalytics', 'grafana', 'miro', 'python', 'targethunter', 'clickhouse', 'powerbi']
  },
  'product': {
    dir: 'product',
    title: "Product",
    text: 'stack.cards.product.main_text',
    tags: [
      'CPO',
      'Product Owner',
      'BizDev',
      'Product Manager'
    ],
    firstCircle: ['google', 'yametrika', 'figma'],
    secondCircle: ['googleanalytics', 'miro', 'jira', 'excel', 'slack', 'mysql', 'canva']
  },
  'backoffice': {
    dir: 'backoffice',
    title: 'Back Office',
    text: 'stack.cards.backoffice.main_text',
    tags: [
      'Finance',
      'HR',
      'Administration'
    ],
    firstCircle: ['google', 'canva', 'figma'],
    secondCircle: ['trello', 'miro', 'coreldraw', 'slack', 'confluence', 'drawio', 'payoneer']
  }
}

export const PRODUCTS = [
  {
    title: 'products.card_one.title',
    tags: ['Kafka', 'Clickhouse', 'K8S'],
    main_text: 'products.card_one.main_text',
    img: 'first_img.png'
  },
  {
    title: 'products.card_two.title',
    tags: ['SSP', 'DSP', 'Top-up', 'GameFI'],
    main_text: 'products.card_two.main_text',
    img: 'second_img.png'
  },
  {
    title: 'products.card_three.title',
    tags: ['AdTech', 'Affiliate', 'Wallet'],
    main_text: 'products.card_three.main_text',
    img: 'third_img.png'
  },
  {
    title: 'products.card_four.title',
    tags: ['Global'],
    main_text: 'products.card_four.main_text',
    img: 'fourth_img.png'
  },
  {
    title: 'products.card_five.title',
    tags: ['SmartSearch', 'SmartSpot', 'AI'],
    main_text: 'products.card_five.main_text',
    img: 'fifth_img.png'
  },
  {
    title: 'products.card_six.title',
    tags: ['GameFI', 'DeFI', 'NFT'],
    main_text: 'products.card_six.main_text',
    img: 'sixth_img.png'
  }
]

export const OFFICE = [
  {
    title: 'moreabout.office_card',
    img: 'office_img1.webp'
  },
  {
    title: 'moreabout.office_card',
    img: 'office_img2.webp'
  },
  {
    title: 'moreabout.office_card',
    img: 'office_img3.webp'
  },
  {
    title: 'moreabout.office_card',
    img: 'office_img4.webp'
  }
]

export const MOREABOUT = [
  {
    title: 'moreabout.card_one',
    img: 'moreabout_img1_1.png',
    img2: 'moreabout_img1_2.png'
  },
  {
    title: 'moreabout.card_two',
    img: 'moreabout_img2_1.png',
    img2: 'moreabout_img2_2.png'
  },
  {
    title: 'moreabout.card_three',
    img: 'moreabout_img3_1.png',
    img2: 'moreabout_img3_2.png'
  },
  {
    title: 'moreabout.card_four',
    img: 'moreabout_img4_1.png',
    img2: 'moreabout_img4_2.png'
  },
  {
    title: 'moreabout.card_five',
    img: 'moreabout_img5_1.png',
    img2: 'moreabout_img5_2.png'
  },
  {
    title: 'moreabout.card_six',
    img: 'moreabout_img6_1.png',
    img2: 'moreabout_img6_2.png'
  },
]

export const DEPARTMENTS_ITEMS = {
  'development': '96',
  'qa': '124',
  'devops': '160',
  'data': '10',
  'management': '107',
  'ui': '34',
  'marketing': '68',
  'backoffice': '137&professional_role=18&professional_role=118&professional_role=88&professional_role=110&professional_role=38&professional_role=69',
  'business': '70',
  'support': '121',
  'product': '73',
  'commerce': '70'
}