<template>
    <div class="job container">
      <div class="link-arrow__wrapper">
        <router-link class="link-arrow" :to="{ name: 'jobs', hash: '#vacancy'}">
          <Icon name="back-arrow" />
          {{ $t("jobs.back") }}
        </router-link>
      </div>
      <div class="job__wrapper">
        <div v-if="isLoading" class="job__left">
          <Loader />
        </div>
        <div v-else class="job__left">
          <div class="job__left__tags">
            <span v-if="position.employment">
              <div class="circle"></div>
              {{ position.employment.name }}
            </span>
            <span v-if="position.experience">
              <div class="circle"></div>
            {{ position.experience.name }}
            </span>
            <span v-if="position.area">
              <div class="circle"></div>
              {{ position.area.name }}
            </span>
            <span v-if="position.schedule">
              <div class="circle"></div>
              {{ position.schedule.name }}
            </span>
          </div>
          <div v-if="isLoading"></div>
          <div ref="position">
            <div class="job__left__title">
              <h2>{{position.name}}</h2>
            </div>
            <div class="job__left__description" v-html="position.description"></div>
            <a :href="position.apply_alternate_url" target="_blank" class="job__btn job__btn__left">{{$t("job.button")}}</a>
            <div class="job__left__similar">
              <div class="job__left__title">
                <h2>{{ $t('job.similar') }}</h2>
              </div>
              <div class="job__left__similar-items">
                <div v-if="isLoading">
                  <Loader />
                </div>
                <div class="job__items" v-else-if="jobs.length">
                  <Job
                    v-for="(job, index) in jobs.slice(0, 6)"
                    :key="`job_${index}`"
                    :params="job"
                    :isVacancyPage="true"
                    :filter="currentFilter"
                    class="job__items__item"
                  ></Job>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="job__right">
          <h2>{{position.name}}</h2>
          <div class="job__left__tags">
            <span v-if="position.employment">
              <div class="circle"></div>
              {{ position.employment.name }}
            </span>
            <span v-if="position.experience">
              <div class="circle"></div>
            {{ position.experience.name }}
            </span>
            <span v-if="position.area">
              <div class="circle"></div>
              {{ position.area.name }}
            </span>
            <span v-if="position.schedule">
              <div class="circle"></div>
              {{ position.schedule.name }}
            </span>
          </div>
          <a :href="position.apply_alternate_url" target="_blank" class="job__btn">{{$t("job.button")}}</a>
          <a href="mailto:info@lofty.today" class="job__right__writeus">
            <div class="job__right__writeus-img">
              <img src="/employee/dasha.jpg" alt="">
            </div>
            <div class="job__right__writeus-text">
              <div class="job__right__writeus-textup">
                {{ $t('job.writeus.name') }}
                <Icon name="tg-black" />
              </div>
              {{ $t('job.writeus.questions') }}
            </div>
          </a>
          <div class="job__right__about">
            Lofty.today
            <span>{{ $t('job.about') }}</span>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import Job from "@/components/Jobs/JobItem.vue"
import Loader from "@/components/Loader";
import {getJob} from "../api";
import { useI18n } from "vue-i18n";
import { getJobs } from "@/api";
import { DEPARTMENTS, DEPARTMENTS_ITEMS } from "@/constants.js"

    export default {
        props:['id'],
        mounted() {
          console.log(this.$route.query.filter);
          if (this.$route.query.filter && this.departments[this.$route.query.filter]) {
            this.currentFilter = this.$route.query.filter;
          }
          this.getData();
          this.getJobs();
        },
        components: {
          Loader,
          Job
        },
        data() {
            return {
                isLoading: false,
                description: null,
                name: '',
                position: {},
                jobs: [],
                currentFilter: 'all',
                departments: DEPARTMENTS,
                departmentsItems: DEPARTMENTS_ITEMS
            }
        },
        methods: {
          async getJobs() {

            this.isLoading = true;

            try {
              const response =  await getJobs(this.departmentsItems[this.$route.query.filter], useI18n().locale.value.toUpperCase())
                this.jobs = response.data.items;
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error(e)
            } finally {
              this.isLoading = false;
            }

          },
            async getData() {

                this.isLoading = true;

                try {

                    const response =  await getJob(this.id, useI18n().locale.value.toUpperCase());
                    if (response.data) {
                      this.position = { ...response.data }
                    }

                } catch (e) {
                    // eslint-disable-next-line no-console
                    console.error(e)
                } finally {
                    this.isLoading = false;
                }

            },
            setLabelPosition() {
              const labels = document.querySelectorAll('.polygot');

              labels.forEach((item) =>  {
                const key = item.innerText.replace(/%/g, '');
                item.innerHTML = this.$te('label.'+key) ? this.$t('label.'+key) : ''
              });
            }
        }
    }
</script>

<style lang="scss" scoped>
.job {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 40px;

  .link-arrow__wrapper {
    @media (max-width: 1440px) {
      display: none;
    }
  }

  &__btn {
    background: $accent-dark;
    color: $text-main;
    border-radius: 12px;
    padding: 15px 25px;
    transition: 0.3s;
    display: inline-block;
    text-align: center;

    font-size: 16px;
    font-weight: 700;
    line-height: 23px;

    &:hover {
      background: $white;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: row;
    gap: 24px;
    align-items: flex-start;
  }

  &__left {
    width: 100%;
    &__tags {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      gap: 8px;
      margin-bottom: 8px;

      span {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
        padding: 6px 8px;
        border-radius: 30px;
        border: 1px solid rgba(142, 235, 229, 0.45);
        font-size: 12px;
        font-weight: 700;
        line-height: 12px;
        text-align: left;
        color: $text-main;
        background: $white;

        .circle {
          width: 7px;
          height: 7px;
          border-radius: 50%;
          background: #8EEBE5;
        }
      }
    }

    &__title {
      h2 {
          font-size: 60px;
          font-weight: 700;
          line-height: 60px;
          letter-spacing: -0.03em;
          text-align: left;
          color: $text-main;
          margin-bottom: 16px;

          @media (max-width: 768px) {
            font-size: 32px;
            line-height: 32px;
          }

          @media (max-width: 360px) {
            font-size: 24px;
            line-height: 24px;
          }
      }
    }

    &__description {
      padding: 16px;
      background: $white;
      border-radius: 16px;

      font-size: 16px;
      font-weight: 500;
      letter-spacing: -0.03em;
    }

    .job__btn__left {
      margin-top: 32px;
    }

    &__similar {
      margin-top: 60px;

      .job__items {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 24px;

        &__item {
          width: calc(50% - 12px);

          @media (max-width: 768px) {
            width: 100%;
          }
        }
      }
    }
  }

  &__right {
    background: #E5F3F8;
    padding: 16px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    max-width: 372px;

    @media (max-width: 1024px) {
      display: none;
    }

    h2 {
          font-size: 24px;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: -0.03em;
          text-align: left;
          color: $text-main;
          margin-bottom: 8px;
    }
    
    .job__btn {
      margin: 16px 0 100px 0;
    }

    &__writeus {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      padding: 15px;
      background: $accent-dark;
      border-radius: 8px;
      transition: 0.3s;

      &-img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 32px;
        }
      }

      &-text {
        display: flex;
        flex-direction: column;
        gap: 4px;

        font-size: 15px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: -0.03em;
        text-align: left;
        color: $text-main;
        white-space: nowrap;
      }

      &-textup {
        color: $text-light;
        display: flex;
        flex-direction: row;
        gap: 10px;
        justify-content: space-between;
        align-items: center;

        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: -0.03em;
        text-align: left;

        .svg-icon {
          width: 10px;
          height: 8px;
        }
      }

      &:hover {
        background: $white;
      }
    }

    &__about {
      margin-top: 8px;
      padding-top: 8px;
      border-top: 1px solid #D2E0E6;
      display: flex;
      flex-direction: column;
      gap: 8px;

      font-size: 24px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: -0.03em;
      color: $text-main;

      span {
        color: $text-light;
        font-size: 10px;
        font-weight: 700;
        line-height: 9.6px;
        text-align: left;
      }
    }
  }
}
</style>