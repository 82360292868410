<template>
    <div class="menu">
      <Icon @click="open" name="menu"/>
      <transition name="slide-fade">
        <div class="menu-overlay" @click.self="close" v-if="isOpen"  v-body-scroll-lock="isOpen">
          <div class="menu-content">
                <div class="menu-close" @click="close">
                    <Icon name="close"></Icon>
                </div>
                <Nav />
              <Actions />
          </div>
        </div>
      </transition>
    </div>
</template>

<script setup>
import Nav from '@/components/Header/Nav.vue';
import Actions from '@/components/Header/Socials.vue';
import { useRoute } from "vue-router";
import { ref, watch } from "vue";

const route = useRoute();

const isOpen = ref(false);

const close = () => {
  isOpen.value = false;
}

const open = () => {
  isOpen.value = true;
}

watch(route, () => {
  close();
});
</script>

<style lang="scss">
    .menu {
        display: none;

        @media (max-width: $tablet-point) {
            display: flex;
        }

        &-content {
            position: fixed;
            top: 0;
            right: 0;
            background: #F1F3F6;
            max-width: 360px;
            width: 360px;
            z-index: 100;
            display: flex;
            flex-direction: column;
            gap: 150px;
            overscroll-behavior: contain;
            border-radius: 0px 0px 16px 16px;
            padding-top: 60px;

            @media (max-width: 360px) {
              // height: 100%;
              width: 100%;
              // border-radius: 0;
            }
        }

        &-nav {
            display: flex;
            flex-direction: column;
            width: fit-content;
            margin: 0 auto;
            justify-content: center;

            a, span{
              font-style: normal;
              font-weight: 700;
              font-size: 36px;
              line-height: 125%;
              color: $text-main;
              text-align: center;
            }

            .social {
              display: flex;
              justify-content: center;

              .svg-icon {
                @media (max-width: $mobile-point) {
                  height: 32px;
                  max-width: 32px;
                  min-width: 32px;
                }
              }
            }
        }

        &-close {
            position: absolute;
            right: 10px;
            top: 16px;
            border-radius: 8px;
            background: rgba(46, 57, 72, 0.05);
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;

            .svg-icon {
              width: 16px;
              height: 16px;
              max-width: 16px;
              min-width: 16px;
            }
        }

        &-overlay {
          position: fixed;
          top: 0;
          left: 0;
          z-index: 9;
          width: 100%;
          height: 100%;
          background: rgba(46, 57, 72, 0.20);
          box-shadow: 46.267px -46.267px 46.267px 0px rgba(165, 165, 165, 0.05) inset, -46.267px 46.267px 46.267px 0px rgba(255, 255, 255, 0.05) inset;
          backdrop-filter: blur(10px);
        }
    }
</style>