<template>
    <div class="stack-card__wrapper">
        <div class="stack-card">
            <h3 class="stack-card__title">{{ props.currentCard.title }}</h3>
            <div class="stack-card__tags">
                <span
                    v-for="tag in currentCard.tags.slice(0, currentLength)"
                    :key="tag"
                    class="stack-card__tags__tag"
                >
                    {{ tag }}
                </span>
                <div
                    v-if="currentCard.tags.length > 4 && currentLength == 4"
                    class="stack-card__tags__tag tag-btn"
                    @click="currentLength = currentCard.tags.length"
                >+</div>
                <div
                    v-if="currentCard.tags.length > 4 && currentLength != 4"
                    class="stack-card__tags__tag tag-btn"
                    @click="currentLength = 4"
                >-</div>
            </div>
            <div class="stack-card__tech">
                <div
                    v-for="
                            item in currentCard.firstCircle ?
                            currentCard.firstCircle : currentCard.secondCircle.slice(0, 3)
                        "
                    :key="item"
                >
                    <Icon :name="`stacktech-${item}`" />
                </div>
            </div>
            <p class="stack-card__text" v-html="$t(props.currentCard.text)"></p>
            <div class="stack-card__button__wrapper">
                <router-link :to="{ hash: '#vacancy', query: { filter: props.currentCard.dir }}" class="stack-card__button">
                    {{ $t('stack.button') }}
                </router-link>
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineProps, ref } from "vue";
const props = defineProps(['currentCard'])

const currentLength = ref(4);
</script>

<style scoped lang="scss">
.stack-card {
    padding: 56px 44px 44px 44px;
    background: $white;
    width: 100%;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    @media (max-width: 768px) {
        padding: 0;
        background: transparent;
    }

    &__wrapper {
        // padding: 0 80px;
        width: 40%;
        display: flex;
        justify-content: center;

        @media (max-width: 1440px) {
            width: 50%;
            padding: 0;
        }

        @media (max-width: 1124px) {
            width: 100%;
        }
    }

    &__title {
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        letter-spacing: -1.2px;
        margin-bottom: 8px;
    }

    &__tags {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 8px;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 7px;

        &__tag {
            padding: 6px 8px;
            border-radius: 30px;
            border: 1px solid rgba(142, 235, 229, 0.45);
            background: #FAFCFE;

            color: $text-main;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            transition: 0.3s;

            @media (max-width: 768px) {
                background: transparent;
            }
        }

        .tag-btn {
            cursor: pointer;

            &:hover {
                background: $accent;
            }
        }
    }

    &__tech {
        display: flex;
        flex-direction: row;
        gap: 7px;
        justify-content: flex-start;
        align-items: center;

        div {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &__text {
        color: $text-main;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        letter-spacing: -0.6px;
        margin: 24px 0 50px 0;
    }

    &__button {
        display: inline-block;
        color: $text-main;
        font-family: Geometria, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;

        padding: 18px 25px 15px 25px;
        background: $accent-dark;
        border-radius: 8px;
        transition: 0.3s;

        &:hover {
            background: $accent;

            @media (max-width: 768px) {
                background: $white;
            }
        }

        &__wrapper {
            width: 100%;

            @media (max-width: 768px) {
                display: flex;
                justify-content: center;
            }
        }
    }
}
</style>