<template>
  <section class="welcome">
    <div class="welcome__animation">
      <video class="video" playsinline autoplay muted loop>
        <source fetchPriority="high" src="/ropes.webm" type="video/webm" />
      </video>
    </div>
    <div class="welcome__overlay">
      <div class="container">
          <h1 class="welcome__title">
            {{ $t("home.title") }}
          </h1>
          <div class="welcome__desc" v-html="$t('home.description')">
          </div>
          <router-link class="welcome__btn" :to="{ name: 'jobs'}">
            {{ $t("home.button") }}
          </router-link>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
  .welcome {
    height: 763px;
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 0 0 24px 24px;
    overflow: hidden;
    margin-bottom: 64px;

    @media (max-width: 768px) {
      height: 430px;
      margin-bottom: 40px;
    }
    @media (max-width: 360px) {
      height: 415px;
      margin-bottom: 24px;
    }

    &__title {
      color: $white;
      font-size: 80px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
      letter-spacing: -2.4px;
      text-align: center;

      @media (max-width: 1124px) {
        font-size: 32px;
        letter-spacing: -0.96px;
      }

      @media (max-width: 360px) {
        font-size: 24px;
        letter-spacing: -0.72px;
        text-align: left;
      }
    }

    &__desc {
      color: $white;
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
      letter-spacing: -0.72px;
      margin: 16px 0 24px 0;
      max-width: 760px;

      @media (max-width: 1124px) {
        font-size: 16px;
        letter-spacing: -0.48px;
      }

      @media (max-width: 360px) {
        font-size: 14px;
        text-align: left;
      }
    }

    &__btn {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 23px;
      padding: 15px 25px;
      border-radius: 12px;
      background: $accent-dark;
      transition: 0.3s;

      &:hover {
        background: $accent;
      }
    }

    .container {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;

      @media (max-width: 360px) {
        align-items: flex-start;
        padding: 28px;
      }
    }

    &__overlay {
      background: rgba(0, 0, 0, 0.35);
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    &__animation {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-color: #1c3a38;

      .video {
        width: 100%;

        @media (max-width: 1350px) {
          width: max-content;
          height: 100%;
        }
      }
    }
  }
</style>