<template>
  <svg :class="className" xmlns="http://www.w3.org/2000/svg" :style="{width: width, height: height}">
    <title v-if="title">{{ title }}</title>
    <use :xlink:href="iconPath" xmlns:xlink="http://www.w3.org/1999/xlink"/>
  </svg>
</template>

<script>
  export default {
    props: {
      name: {
        type: String,
        required: true
      },
      title: {
        type: String,
        default: null
      },
      width: {
        type: String,
        default: null
      },
      height: {
        type: String,
        default: null
      }
    },
    computed: {
      iconPath() {
        let icon = require(`@/assets/icons/${this.name}.svg`);

        if (Object.prototype.hasOwnProperty.call(icon, 'default')) {
          icon = icon.default;
        }
        return icon.url ? icon.url : `#${icon.id}`;
      },
      className() {
        return 'svg-icon svg-icon-' + this.name;
      }
    }
  };
</script>
