import { defineStore } from "pinia";
import { reactive } from "vue";

export const useSize = defineStore("size", () => {
    const size = reactive({
        l: false,
        md: false,
        m: false,
        s: false,
        xs: false,
        xxs: false,
        current: 0,
    });

    const onResize = () => {
        const width = window.innerWidth || document.documentElement.clientWidth;
        size.l = width > 1024;
        size.md = width <= 1024;
        size.m = width < 768;
        size.s = width < 550;
        size.xs = width < 450;
        size.xxs = width < 360;
        size.current = width;
    };

    return { size, onResize };
});
