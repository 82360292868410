<template>
    <div class="card" :class="{ 'card__show-background': show }">
        <div class="card__tags">
          <span v-for="tag in props.item.tags" :key="tag" class="card__tags__tag">
            <div class="card__tags__circle"></div>
            <span v-if="tag.indexOf('.') > 0">
                {{ $t(tag) }}
            </span>
            <span v-else>{{ tag }}</span>
          </span>
        </div>
        <p v-if="!show" class="card__title">{{ $t(props.item.title) }}</p>
        <p v-else class="card__desc">{{ $t(props.item.main_text) }}</p>
        <div v-lazy="`products/${props.item.img}`" class="card__img"></div>
        <!-- <img class="card__img" :src="`products/${props.item.img}`" alt=""> -->
        <router-link to="/jobs" class="card__button" v-if="show">
          {{ $t('products.button_join') }}
        </router-link>
        <div class="card__show" @click="show = !show">
          <Icon v-if="!show" name="plus" />
          <Icon v-else name="minus" />
        </div>
    </div>
</template>

<script setup>
import { ref, defineProps } from "vue";

const props = defineProps(['item']);

const show = ref(false);
</script>

<style scoped lang="scss">
.card {
    background: $white;
    width: 100%;
    padding: 24px;
    aspect-ratio: 1 / 1;

    @media (max-width: 360px) {
       padding: 14px;
    }

    &__show-background {
        background: $accent-two;
    }

    &__tags {
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: center;
      margin-bottom: 8px;

      @media (max-width: 768px) {
        overflow-x: scroll;

        &::-webkit-scrollbar {
            display: none;
        }
      }

      &__tag {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
        background: $white;

        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        padding: 6px 8px;

        border-radius: 30px;
        border: 1px solid $accent;
      }

      &__circle {
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: #8EEBE5;
      }
    }

    &__title {
      color: $text-main;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
      letter-spacing: -1.2px;
      position: relative;
      z-index: 1;
      // backdrop-filter: blur(3px);

      @media (max-width: 1440px) {
        font-size: 30px;
      }

      @media (max-width: 768px) {
        font-size: 25px;
        letter-spacing: -0.9px;
      }

      // @media (max-width: 360px) {
      //   font-size: 25px;
      //   line-height: 120%;
      // }
    }

    &__desc {
      color: $text-main;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
      letter-spacing: -0.6px;
      position: relative;
      z-index: 1;
      backdrop-filter: blur(3px);

      @media (max-width: 1440px) {
        font-size: 16px;
      }
    }

    &__img {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 80%;
      height: 80%;
      background-position: bottom;
      background-repeat: no-repeat;
      background-size: contain;

      @media (max-width: 360px) {
        transform: scale(-1, 1);
        left: 0;
      }
    }

    &__button {
        position: absolute;
        bottom: 28px;
        left: 28px;
        padding: 20px 40px;
        border-radius: 12px;
        border: 1px solid rgba(255, 255, 255, 0.10);
        background: rgba(140, 140, 140, 0.10);
        backdrop-filter: blur(25px);
        transition: 0.3s;

        color: $text-main;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        letter-spacing: -0.48px;

        @media (max-width: 1440px) {
            padding: 12px 32px;
        }

        @media (max-width: 360px) {
            bottom: 14px;
            left: 14px;
        }

        &:hover {
            background: $accent-dark;
        }
    }

    &__show {
        position: absolute;
        bottom: 28px;
        right: 28px;
        width: 58px;
        height: 58px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $white;
        border-radius: 50%;
        cursor: pointer;
        transition: 0.3s;

        .svg-icon {
            width: 18px;
            max-width: 18px;
            min-width: 18px;
        }

        @media (max-width: 1440px) {
            width: 46px;
            height: 46px;
        }

        @media (max-width: 360px) {
            bottom: 14px;
            right: 14px;
            background: rgba(46, 57, 72, 0.15);
        }

        &:hover {
            background: $accent;

            @media (max-width: 360px) {
                background: rgba(46, 57, 72, 0.15);
            }
        }
    }
}
</style>