<template>
    <section class="moreabout__wrapper">
        <h2 v-if="isOffice">{{ $t('moreabout.office') }}</h2>
        <div class="moreabout container">
            <swiper
                :slides-per-view="1"
                @init="onInit"
                :loop="false"
                :pagination="isPagination"
                class="moreabout__swiper"
            >
                <swiper-slide
                    v-for="item in isOffice ? OFFICE : MOREABOUT"
                    :key="item"
                    class="moreabout__swiper__slide"
                >
                    <div v-if="item.img" class="moreabout__swiper__img-wrapper">
                        <div v-if="isOffice" v-lazy="`/moreabout/${item.img}`" class="moreabout__swiper__img-single"></div>
                        <div v-if="!isOffice" v-lazy="`/moreabout/${item.img}`" class="moreabout__swiper__img-double"></div>
                        <div v-if="!isOffice" v-lazy="`/moreabout/${item.img2}`" class="moreabout__swiper__img-double second"></div>
                    </div>
                    <div class="moreabout__swiper__slide__right">
                        <div class="moreabout__swiper__slide__right-block">
                            <h4>{{ $t('moreabout.title') }}</h4>
                            <p class="moreabout__swiper__title" v-html="$t(item.title)">
                            </p>
                        </div>
                        <div class="swiper__navigation desktop">
                            <div class="swiper__prev" @click="swiper.slidePrev()">
                                <Icon name="arrow"></Icon>
                            </div>
                            <div class="swiper__next" @click="swiper.slideNext()">
                                <Icon name="arrow"></Icon>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
            </swiper>
        </div>
    </section>
</template>

<script>
import { MOREABOUT, OFFICE } from "@/constants.js";
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Pagination } from 'swiper';
import { useSize } from "@/js/useSize";

SwiperCore.use([Pagination]);
export default {
    setup() {
        const { size } = useSize();

        return {
            size,
            MOREABOUT,
            OFFICE
        };
    },
    props: ['isOffice'],
    methods: {
        onInit(swiper) {
            this.swiper = swiper
        }
    },
    computed: {
        isPagination() {
            if (this.size.md) {
                return true;
            } else {
                return false;
            }
        }
    },
    components: {
        Swiper,
        SwiperSlide
    }
}
</script>

<style lang="scss">
.moreabout {
    display: flex;
    flex-direction: column;
    gap: 22px;

    .desktop {
        @media (max-width: 1024px) {
            display: none;
        }
    }

    &__wrapper {
        position: relative;
        overflow: hidden;
        padding: 70px 0;

        @media (max-width: 768px) {
            padding: 48px 0 30px 0;
        }

        h2 {
            font-family: Geometria, sans-serif;
            font-size: 40px;
            font-weight: 700;
            line-height: 40px;
            letter-spacing: -0.03em;
            text-align: center;
            margin-bottom: 24px;
            justify-content: center;
        }
    }

    &__navigation {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        @media (max-width: 360px) {
            justify-content: center;

            .swiper__navigation {
                display: none;
            }
        }
    }

    &__swiper {
        width: 100%;

        &__slide {
            display: flex;
            flex-direction: row;
            align-items: stretch;

            @media (max-width: 1024px) {
                flex-direction: column-reverse;
                justify-content: center;
                height: fit-content;
            }

            &__right {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;
                height: 100%;
                gap: 16px;
                width: 33%;
                margin-left: 24px;

                &-block {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 16px;
                }

                h4 {
                    margin: 0;
                    color: $text-main;
                    font-family: Geometria, sans-serif;
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 24px;
                    padding: 6px 8px;
                    border-radius: 30px;
                    background: #CEF1F3;

                    @media (max-width: 1024px) {
                        font-size: 20px;
                        line-height: 20px;
                    }

                    @media (max-width: 360px) {
                        margin: 0 auto;
                    }
                }

                @media (max-width: 1024px) {
                    width: 100%;
                    margin-left: 0;
                    margin-bottom: 24px;
                    height: fit-content;
                }
            }
        }

        &__title {
            font-family: Geometria, sans-serif;
            font-size: 32px;
            font-weight: 500;
            line-height: 38px;
            text-align: left;
            color: $text-main;

            @media (max-width: 1440px) {
                font-size: 24px;
                line-height: 28px;
            }

            @media (max-width: 360px) {
                font-size: 15px;
                line-height: 15px;
            }
        }

        .second {
            @media (max-width: 550px) {
                display: none;
            }
        }

        &__img {
            &-single {
                width: 100%;
                aspect-ratio: 52 / 25;
                background-size: cover;
                border-radius: 16px;
            }

            &-double {
                width: calc(50% - 12px);
                aspect-ratio: 41 / 37;
                background-size: cover;
                background-position: center;
                border-radius: 16px;

                @media (max-width: 1024px) {
                    aspect-ratio: 40 / 48;
                }

                @media (max-width: 550px) {
                    width: 100%;
                }
            }

            &-wrapper {
                width: 66%;
                display: flex;
                flex-direction: row;
                gap: 24px;

                @media (max-width: 1024px) {
                    width: 100%;
                }
            }
        }
    }
}

.swiper-pagination {
    bottom: 0 !important;
}
</style>