<template>
    <section class="mainpoints container" id="about">
        <div class="mainpoints__card">
            <div class="mainpoints__card__upper">
                <h3 class="title">{{ $t('mainpoints.card1.title') }}</h3>
                <h4 class="subtitle">{{ $t('mainpoints.card1.subtitle') }}</h4>
            </div>
            <div class="mainpoints__card__lower">
                <img class="team-three" src="/photos/mainpoints/team-1.webp" alt="">
                <img class="team-two" src="/photos/mainpoints/team-2.webp" alt="">
                <img class="team-one" src="/photos/mainpoints/team-3.webp" alt="">
            </div>
        </div>
        <div class="mainpoints__card">
            <div class="mainpoints__card__upper">
                <h3 class="title">{{ $t('mainpoints.card2.title') }}</h3>
                <h4 class="subtitle">{{ $t('mainpoints.card2.subtitle') }}</h4>
            </div>
            <div class="mainpoints__card__lower-products">
                <img src="/photos/mainpoints/pr1.svg" alt="">
                <img src="/photos/mainpoints/pr2.svg" alt="">
                <img src="/photos/mainpoints/pr3.svg" alt="">
                <img src="/photos/mainpoints/pr4.svg" alt="">
                <img src="/photos/mainpoints/pr5.svg" alt="">
                <img src="/photos/mainpoints/pr6.svg" alt="">
                <div class="plus">+</div>
            </div>
        </div>
        <div class="mainpoints__card">
            <div class="mainpoints__card__upper">
                <h3 class="title">{{ $t('mainpoints.card3.title') }}</h3>
                <h4 class="subtitle">{{ $t('mainpoints.card3.subtitle') }}</h4>
            </div>
            <div class="mainpoints__card__lower">
                <img style="width: 95%" src="/photos/mainpoints/sites.svg" alt="">
            </div>
        </div>
        <div class="mainpoints__card">
            <div class="mainpoints__card__upper">
                <h3 class="title">{{ $t('mainpoints.card4.title') }}</h3>
                <h4 class="subtitle">{{ $t('mainpoints.card4.subtitle') }}</h4>
            </div>
            <div class="mainpoints__card__lower">
                <img
                    class="mainpoints__card__lower__graph"
                    src="/photos/mainpoints/impressions.svg"
                    alt=""
                >
            </div>
        </div>
    </section>
</template>

<style scoped lang="scss">
.mainpoints {
    display: flex;
    flex-direction: row;
    gap: 20px;
    box-sizing: border-box;
    margin-bottom: 64px;

    @media (max-width: 768px) {
        flex-wrap: wrap;
        gap: 10px;
        margin-bottom: 40px;
    }

    @media (max-width: 525px) {
        flex-direction: column;
    }

    @media (max-width: 360px) {
        margin-bottom: 24px;
    }

    &__card {
        width: 100%;
        background: $accent-two;
        aspect-ratio: 1 / 1;
        border-radius: 16px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media (max-width: 768px) {
            width: calc(50% - 5px);
        }

        @media (max-width: 525px) {
            width: 100%;
        }

        &__upper {
            padding: 24px 24px 12px 24px;
            backdrop-filter: blur(3px);
            z-index: 1;

            .title {
                font-size: 40px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
                letter-spacing: -1.2px;
            }

            .subtitle {
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
                letter-spacing: -0.54px;
                margin: 0;
            }
        }

        &__lower {
            display: flex;
            position: relative;

            .team-one {
                border-radius: 0 12px 0 0;
                position: absolute;
                bottom: 0px;
                left: 0;
                width: 40%;
            }

            .team-two {
                border-radius: 0 12px 0 0;
                position: absolute;
                bottom: -10px;
                left: 0;
                transform: translate(85%, 0) rotate(4deg);
                width: 38%;
            }

            .team-three {
                border-radius: 0 12px 0 0;
                position: absolute;
                bottom: -10px;
                left: 60px;
                transform: translate(140%, 0) rotate(10deg);
                width: 32%;
            }

            &-products {
                padding: 0 24px 24px 24px;
                display: flex;
                flex-direction: row;
                gap: 10px;
                flex-wrap: wrap;

                img {
                    width: calc(100% / 4 - 10px);
                    transition: 0.3s;

                    &:hover {
                        transform: scale(1.1);
                    }
                }

                .plus {
                    width: calc(100% / 4 - 10px);
                    background: rgba(255, 255, 255, 0.5);
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 30px;
                    font-weight: 700;
                }

                @media (max-width: 1440px) {
                    gap: 8px;
                }
            }

            &__graph {
                width: 100%;
            }
        }
    }
}
</style>