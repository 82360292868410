const loadScript = () => {

  return new Promise((resolve) => {

    if(window.grecaptcha) {
      resolve();
    }

    let script = document.createElement('script');
    script.src = "https://www.google.com/recaptcha/api.js?render="+'6Ldyd7cUAAAAAKrQiPRvsArENgfiKPiUWqWDFbGP';
    script.onload = () => {
      window.grecaptcha.ready(() => {
        resolve();
      });
    }

    window.document.head.appendChild(script);
  });
}

export const grecaptchaExecute = async () => {
  await loadScript();
  return await window.grecaptcha.execute('6Ldyd7cUAAAAAKrQiPRvsArENgfiKPiUWqWDFbGP', { action: 'login' })
}