<template>
    <div class="joinus">
        <div class="joinus__photos">
            <img src="/join/1.jpeg" alt="">
            <img class="joinus__photos__two" src="/join/2.jpeg" alt="">
            <img class="joinus__photos__three" src="/join/4.jpeg" alt="">
            <img class="joinus__photos__four" src="/join/3.jpeg" alt="">
            <div class="joinus__photos__count">600+</div>
        </div>
        <h2>{{ $t('join.title') }}</h2>
        <span>{{ $t('join.description') }}</span>
    </div>
</template>

<style scoped lang="scss">
.joinus {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    max-width: 800px;

    @media (max-width: 1440px) {
        max-width: 1000px;
    }

    &__photos {
        position: relative;
        display: flex;
        align-items: center;
        transform: translate(10%);

        img {
            width: 90px;
            height: 90px;
            border-radius: 50%;
            
            @media (max-width: 768px) {
                width: 57px;
                height: 57px;
            }

            @media (max-width: 360px) {
                width: 32px;
                height: 32px;
            }
        }

        &__two {
            transform: translateX(-20%);
        }

        &__three {
            transform: translateX(-40%);
        }

        &__four {
            transform: translateX(-60%);
        }

        &__count {
            padding: 26px 43px;
            border: 1.5px solid #A5D7D6;
            background: rgba(141, 234, 228, 0.4);
            color: $text-main;
            font-size: 40px;
            font-weight: 500;
            line-height: 40px;
            border-radius: 550px;
            transform: translateX(-40%);
            backdrop-filter: blur(10px);

            @media (max-width: 768px) {
                font-size: 25px;
                line-height: 32px;
                padding: 16px 27px;
            }

            @media (max-width: 360px) {
                font-size: 16px;
                line-height: 20px;
                padding: 7px 13px;
            }
        }
    }

    h2 {
        font-family: Geometria, sans-serif;
        font-size: 80px;
        font-weight: 700;
        line-height: 72px;
        letter-spacing: -0.03em;
        text-align: center;
        margin: 0;

        @media (max-width: 1440px) {
            font-size: 40px;
            line-height: 40px;
        }

        @media (max-width: 768px) {
            font-size: 32px;
            line-height: 32px;
        }

        @media (max-width: 360px) {
            font-size: 30px;
            line-height: 28px;
        }
    }

    span {
        font-family: Geometria, sans-serif;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        text-align: center;

        @media (max-width: 360px) {
            font-size: 16px;
            line-height: 20px;
        }
    }
}
</style>