<template>
  <section class="products container" id="products">
    <h3 v-html="$t('products.title') "></h3>
    <h4 v-html="$t('products.subtitle')"></h4>
    <div class="products__cards">
      <div v-for="item of cards" :key="item" class="products__card">
        <ProductsCard :item="item" />
      </div>
      <button @click="showCards()" class="products__cards__button" v-if="size.xxs">
        {{ $t(button) }}
      </button>
    </div>
  </section>
</template>

<script setup>
import { ref, computed } from "vue";
import { PRODUCTS } from "@/constants.js"
import ProductsCard from "@/components/Home/Products/ProductsCard.vue"
import { useSize } from "@/js/useSize";

const { size } = useSize();

const currentLength = ref(3);

const button = ref('products.button_more');

const showCards = () => {
  if (currentLength.value == 3) {
    currentLength.value = 6;
    button.value = 'products.button_less';
  } else {
    currentLength.value = 3;
    button.value = 'products.button_more';
  }
};

const cards = computed(() => {
  if (size.xxs) {
    return PRODUCTS.slice(0, currentLength.value);
  } else {
    return PRODUCTS;
  }
});
</script>

<style lang="scss" scoped>
.products {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 64px;

  @media (max-width: 768px) {
    margin-bottom: 0;
  }
  
  h3 {
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -1.2px;

    @media (max-width: 768px) {
      font-size: 32px;
    }

    @media (max-width: 360px) {
      font-size: 24px;
    }
  }

  h4 {
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -0.6px;
    margin: 0;

    @media (max-width: 360px) {
      font-size: 16px;
    }
  }

  &__cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
    justify-content: space-between;
    box-sizing: border-box;

    @media (max-width: 1220px) {
      gap: 12px;
    }

    @media (max-width: 680px) {
      flex-direction: column;
    }

    &__button {
      display: inline-block;
      color: $text-main;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;

      padding: 17px 24px;
      border-radius: 12px;
      background: $accent-dark;
      border: none;
      margin-top: 12px;
    }
  }

  &__card {
    width: calc((100% - 48px) / 3);
    aspect-ratio: 1 / 1;
    background: $white;
    border-radius: 16px;
    position: relative;
    overflow: hidden;

    @media (max-width: 1220px) {
      width: calc((100% - 12px) / 2);
    }

    @media (max-width: 680px) {
        width: 100%;
    }
  }
}
</style>