import { createRouter, createWebHistory  } from 'vue-router'

import Home from '../views/Home.vue'
import Jobs from '../views/Jobs.vue'
import Job from '../views/Job.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/jobs',
    name: 'jobs',
    component: Jobs,
  },
  {
    path: '/jobs/:id',
    name: 'job',
    component: Job,
    props: true
  },
  {
    path: "/:catchAll(.*)",
    redirect: { name: 'home' }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior (to) {
    if (to.hash) {
      return window.scrollTo({ top: document.querySelector(to.hash).offsetTop - 50, behavior: 'smooth' });
    } else {
      return { top: 0 }
    }
  }
})


export default router
