<template>
  <div :class="{'no-touch': !isTouch}">
    <Header />
    <router-view :key="keyRouter" />
    <Footer />
    <notifications group="foo" position="top left" />
  </div>
</template>

<script setup>
import Header from '@/components/Header/Header'
import Footer from '@/components/Footer/Footer'
import './styles/index.scss'
import { ref, onBeforeMount, onMounted, computed } from "vue";
//import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import { useSize } from "@/js/useSize";

const { onResize } = useSize();
const isTouch = ref(false);
const route = useRoute();

const keyRouter = computed(() => {
  if (route.fullPath.includes("/jobs/")) {
    return route.params.id;
  }

  return route.fullPath;
});

const checkTouch = () => {
  /* eslint-disable */
  if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
    isTouch.value = true;
  }
  /* eslint-enable */
}

onBeforeMount(() => {
  onResize();
  window.addEventListener("resize", onResize, {
    passive: true,
  });
});

onMounted(() => {
  checkTouch();
});
</script>