<template>
    <div class="lang-select" @click="toogleLang = !toogleLang">
        <Icon class="lang-select__flag" :name="locale" />
        {{ locale }}
        <Icon name="chevron" />
        <div class="lang-select__langlist" v-if="toogleLang">
            <span
                class="lang-select__item"
                v-for="item in langs"
                :key="item"
                @click="changeLang(item)"
            >{{item}}</span>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";
import { useI18n } from "vue-i18n";

const { locale } = useI18n();
const langs = ['ru', 'en'];

const toogleLang = ref(false);

const changeLang = (lang) => {
    locale.value = lang;
}
</script>

<style scoped lang="scss">
.lang-select {
        cursor: pointer;
        padding: 10px 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        border: 1px solid $border-color;
        border-radius: 8px;
        position: relative;

        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;

        .svg-icon {
            width: 16px;
            height: 16px;
            min-height: 16px;
            min-width: 16px;
            max-width: 16px;
            max-height: 16px;
            color: $text-main;
        }

        &__langlist {
            position: absolute;
            top: 100%;
            left: 0;
            background: $bg;
            border-radius: 8px;
            border: 1px solid $border-color;
            z-index: 2;
            padding: 10px;
            display: flex;
            flex-direction: column;
            gap: 5px;
            box-sizing: border-box;

            span {
                padding: 5px;
                border-radius: 5px;
                &:hover {
                    background: $accent;
                }
            }
        }

        @media (max-width: 768px) {
            padding: 8px;
        }
}
</style>